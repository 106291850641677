import {Client} from '../../../shared/utilities/type-helpers';
const client: Client = {clientType: 'terrific'};

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyAPZknR4CKSGuhETh_o9OEOdWX0uIsvbr4',
    authDomain: 'testing.terrific.live',
    projectId: 'terrific-testing',
    storageBucket: 'terrific-testing.appspot.com',
    messagingSenderId: '355113223700',
    appId: '1:355113223700:web:99e05b08e5322202c64901',
    measurementId: 'G-ZM2YW60C78',
  },
  useEmulators: false,
  maxUplodedImageSize: 5000001,
  allowImageExt: '.jpg,.png,.jpeg',
  featureFlags: {longPolling: true},
  client,
};
